import React from 'react';
import type { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button, { VARIANTS } from 'common/components/Button';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';

import { performMigration } from '../../redux/actions';
import {
  getCurrentDomainCname,
  getTargetDomainCname,
  getAssets,
  getSkipChildMigration,
  getSkipAllButSpecified,
  getDoingMigration
} from '../../redux/selectors';
import { Asset } from '../../types';

interface Props {
  onCancel: () => void;
}

const renderAssetList = (assets: { [uid: string]: Asset }) => {
  if (assets.length === 0) {
    return <div className="alert error"> All assets</div>;
  }

  return (
    <ul>
      {Object.values(assets)
        .filter((asset) => asset.view && !asset.error)
        .map((asset) => (
          <li>
            <code>{asset.view?.id}</code>: {asset.view?.name}
          </li>
        ))}
    </ul>
  );
};

const ConfirmMigrationModal: FunctionComponent<Props> = ({ onCancel }) => {
  const dispatch = useDispatch();
  const doingMigration = useSelector(getDoingMigration);
  const currentDomainCname = useSelector(getCurrentDomainCname);
  const targetDomainCname = useSelector(getTargetDomainCname);
  const assets = useSelector(getAssets);
  const skipChildMigration = useSelector(getSkipChildMigration);
  const skipAllButSpecified = useSelector(getSkipAllButSpecified);

  const confirmButtonDisabled = !targetDomainCname;

  return (
    <Modal onDismiss={onCancel}>
      <ModalHeader title="Confirm Migration" onDismiss={onCancel} />
      <ModalContent>
        <div>
          <strong>From domain:</strong> {currentDomainCname}
        </div>
        <div>
          <strong>To domain:</strong>{' '}
          {targetDomainCname ? (
            targetDomainCname
          ) : (
            <div className="alert error">No target domain selected; please select one before continuing</div>
          )}
        </div>
        <div>
          <strong>Assets:</strong>
          {renderAssetList(assets)}
        </div>
        <div>
          <strong>Skip child migration?</strong> {skipChildMigration ? 'Yes' : 'No'}
        </div>
        <div>
          <strong>Skip all but specified?</strong> {skipAllButSpecified ? 'Yes' : 'No'}
        </div>
      </ModalContent>
      <ModalFooter className="confirm-migration-modal-footer">
        <Button disabled={doingMigration} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => dispatch(performMigration())}
          busy={doingMigration}
          disabled={confirmButtonDisabled}
          variant={VARIANTS.PRIMARY}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmMigrationModal;
