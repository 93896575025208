import { call, put, select, all, takeEvery } from 'redux-saga/effects';

import type { View } from 'common/types/view';
import { fetchJsonWithParsedError } from 'common/http';

import { ActionTypes, assetLoaded, migrationFinished } from './actions';
import { getAssets, getTargetDomainCname, getSkipChildMigration, getSkipAllButSpecified } from './selectors';
import { Asset } from '../types';
import getWarning from '../getWarning';

/**
 * Handles parsing a result from a call to /api/views.
 * Will result in putting an `assetLoaded` action with either the loaded view,
 * or an error message.
 *
 * @param result Fetch result from hitting /api/views
 */
export function* handleApiViewsResult(result: Response) {
  const urlParts = result.url.split('/');
  const uid = urlParts[urlParts.length - 1];

  if (result.status === 404) {
    yield put(assetLoaded({ uid, error: 'Not Found On This Domain' }));
  } else if (result.status === 200) {
    const view: View = yield result.json();

    // we have the view; getWarning here will return undefined if it's good to go
    yield put(assetLoaded({ uid, view, warning: getWarning(view) }));
  } else {
    yield put(assetLoaded({ uid, error: `Unknown Error (${result.status} - ${result.statusText})` }));
  }
}

export function* addAssetUids() {
  const assets: { [uid: string]: Asset } = yield select(getAssets);

  // full list of assets we have not fetched yet
  const assetsToFetch = Object.keys(assets).filter((uid) => !assets[uid].error && !assets[uid].view);

  // gotta fetch em all
  while (assetsToFetch.length > 0) {
    const fetches = [];

    // fetch 5 at a time
    for (let i = 0; i < 5; i++) {
      const asset = assetsToFetch.shift();

      // reached the end of the list
      if (!asset) {
        break;
      }

      // NOTE: we're NOT using our usual `common/http` functions here because we want
      // to manually check the status and not throw an error
      fetches.push(call(fetch, `https://${window.socrata.domain}/api/views/${asset}`));
    }

    // this will wait for all 5 fetches to finish
    const results: Response[] = yield all(fetches);

    // and this will result in an `assetLoaded` action that the reducer picks up
    for (const result of results) {
      yield handleApiViewsResult(result);
    }
  }
}

export function* migrateAssets() {
  const assets: { [uid: string]: Asset } = yield select(getAssets);
  const targetDomainCname: string = yield select(getTargetDomainCname);
  const skipChildMigration: boolean = yield select(getSkipChildMigration);
  const skipAllButSpecified: boolean = yield select(getSkipAllButSpecified);

  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error
    const result = yield call(fetchJsonWithParsedError, '/api/migrate_domain', {
      method: 'POST',
      body: JSON.stringify({
        newDomain: targetDomainCname,
        uids: Object.keys(assets).filter((uid) => assets[uid].view && !assets[uid].error),
        skipChildMigration,
        skipAllButSpecified
      })
    });

    yield put(migrationFinished(result));
  } catch (error) {
    yield put(migrationFinished(error.json, error.requestId));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.AddAssetUids, addAssetUids),
    takeEvery(ActionTypes.PerformMigration, migrateAssets)
  ]);
}
